<script>
import get from "lodash/get";
import { VMenu, VDialog } from "vuetify/lib";
import { mapState, mapMutations, mapActions } from "vuex";

export default {
  name: "StoreSelect",
  components: {
    StoreAvatar: () => import("@/components/StoreAvatar"),
  },
  inheritAttrs: false,
  props: {
    type: {
      type: String,
      default: "menu",
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  inject: {
    currentStore: {
      default() {
        return () => [];
      },
    },
    getStores: {
      default() {
        return () => [];
      },
    },
  },
  computed: {
    selectedStore() {
      return this.currentStore();
    },
    activeStoreId() {
      return this.selectedStore?.id;
    },
    otherStores() {
      return this.getStores()?.filter(
        (store) => store.id.toString() !== this.activeStoreId
      );
    },
    is() {
      if (this.type === "dialog") return VDialog;
      return VMenu;
    },
  },
  methods: {
    switchStore(storeId, storeName) {
      if (this.$ga) {
        this.$ga.event("UI", "SwitchStore", storeName);
      }
      this.$router.push({ params: { storeId }, preserveQuery: true });
    },
  },
};
</script>

<template>
  <component
    :is="is"
    :key="activeStoreId"
    min-width="200"
    offset-y
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template v-slot:activator="{ on }">
      <slot name="activator" :on="on" :selectedStore="selectedStore">
        <v-btn ripple icon data-testid="storeSelectBtn" v-on="on">
          <StoreAvatar :store-id="activeStoreId" :size="32" />
        </v-btn>
      </slot>
    </template>
    <template #default>
      <slot
        :selected-store="selectedStore"
        :other-stores="otherStores"
        :switch="switchStore"
      >
        <v-card>
          <v-list class="pt-0">
            <v-list-item
              class="v-list-item v-list-item--active v-list-item--link"
              two-line
            >
              <v-list-item-avatar class="mr-1">
                <StoreAvatar :store-id="selectedStore.id" size="32" />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title
                  v-text="selectedStore.name"
                ></v-list-item-title>
                <v-list-item-subtitle
                  class="rai-data"
                  v-text="selectedStore.storeNumber"
                ></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item
              v-for="store in otherStores"
              :key="store.id"
              dense
              :data-testid="`store-list-item-${store.name
                .toLowerCase()
                .split(' ')
                .join('-')}`"
              @click="switchStore(store.id, store.name)"
            >
              <v-list-item-avatar class="mx-1 my-0">
                <StoreAvatar :store-id="store.id" :size="24" />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title v-text="store.name"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </slot>
    </template>
  </component>
</template>

<style>
.btn.title_menu {
  text-transform: capitalize;
}
.btn.title_menu .btn__content {
  justify-content: left;
}
.btn.title_menu .toolbar__title {
  margin-left: 0;
}
.v-menu__activator--active .toolbar__title .icon {
  transform: rotate(-180deg);
}
</style>
